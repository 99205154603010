import React from 'react'
import BlogHeader from '../../blog_header/blog_header'
import * as classess from '../blogs_styling.module.css'
import p_emp from './static/pre_employment.png'
import p_emp_webp from './static/pre_employment.webp'

import second_img from './static/p_emp_1.png'
import second_img_webp from './static/p_emp_1.webp'

import cognitive_img from './static/cognitive_test_img.png'
import cognitive_img_webp from './static/cognitive_test_img.webp'

import personality_test from './static/personality_test.png'
import personality_test_webp from './static/personality_test.webp'

import emotional_test from './static/emotional_test.png'
import emotional_test_webp from './static/emotional_test.webp'

import risk_test from './static/risk_test.png'
import risk_test_webp from './static/risk_test.webp'

import skill_test from './static/skill_test.png'
import skill_test_webp from './static/skill_test.webp'

import Scroll from '../scroll/scroll'



import Footer from '../../../footer/footer'
import Social from '../../../social/social'


const PreEmploymentTest = (props) => {
    return <React.Fragment>
        <Scroll showBelow={250} />
        <BlogHeader title="5 Types of Pre Employment Tests in 2021"/>
        <div className={classess.blog_content_main}>
            <h1 className={classess.heading_one}>
              5 Types of Pre Employment Tests in 2021
            </h1>
            <picture>
                <source srcSet={p_emp_webp} type="image/webp" />
                <source srcSet={p_emp} type="image/png" />
                <img srcSet={p_emp_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
            </picture>
            <div className={classess.content_styling}>
                Pre Employment tests are all the rage these days for recruiters. CVs and interviews come secondary to tests that determine which candidate is most suitable for the job. The race to hire the best talent, means having the right pre employment assessment techniques, and in this article we will discuss all the times of such tests, their benefits and limitations, so you can choose which one is the most suitable for your hiring process.
            </div>
            <h2 className={classess.heading_two}>
                What Are Pre Employment Tests?
            </h2>
            <div className={classess.content_styling}>
                Pre Employment tests are quickly getting popular within the HR world. They are specifically designed exams and assessments that allow employers and organizations to gauge candidates’ abilities per the job they are applying to. These tests do not focus on the traditional hiring methods such as CVs, resumes, or statements of purpose and instead focus on compatibility
            </div>
            <div className={classess.content_styling}>
                These tests range from cognitive testing to emotional testing and attempt to increase productivity within not only the process of hiring but also afterwards since they are designed to hire the best possible candidate.
            </div>
            <h2 className={classess.heading_two}>
                Benefits of Pre Employment Testing
            </h2>
            <center>
                <picture>
                    <source srcSet={second_img_webp} type="imgae/webp" />
                    <source srcSet={second_img} type="image/png" />
                    <img srcSet={second_img_webp} alt="Sub" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <ul className={classess.list_styling}>
                <li>
                    1. <b>Reduce Turnover:</b> Each test is designed to find the perfect candidate, ensuring they perform well in the given job.
                </li>
                <li>
                    2. <b>Saves Time:</b> The use of pre-employment testing can save employers and HR managers the tedious and often inaccurate task of going through hundreds and hundreds of CVs. This saved time can instead be invested in an even more important part of the hiring process which is the interview phase.
                </li>
                <li>
                    3. <b>Increased Productivity:</b> By hiring the most suitable candidate, the employer is ensuring that the person hired can not only produce the highest quality work but also that it would be following company policy and requirement.
                </li>
                <li>
                    4. <b>Reduces Workplace Issues and Conflict:</b> Through the various types of pre-employment assessments, it can be assured that the person being hired is not only good for the job but also fits the company's work environment, is a team player, etc.
                </li>
            </ul>
            <h2 className={classess.heading_two}>
                Types of Pre-Employment Testing
            </h2>
            <h3 className={classess.heading_three}>
                1. Cognitive Testing
            </h3>
            <center>
                <picture>
                    <source srcSet={cognitive_img_webp} type="imgae/webp" />
                    <source srcSet={cognitive_img} type="image/png" />
                    <img srcSet={cognitive_img_webp} alt="Sub" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
                Cognitive Tests are a type of pre-employment test that help gauge the cognitive ability of the employee, focusing on aspects such as memory, comprehension, reasoning, analysis, and response, etc. This type of testing typically includes aptitude tests focused on testing understanding, reading, writing, and even arithmetic ability to see if the applicant is the correct fit for the position they are applying to.
            </div>
            <div className={classess.content_styling}>
                Aptitude tests are designed to single out candidates with the required skill set for the particular job, making them the best way to judge candidates for skills that cannot be pinpointed through conventional methods of CVs and resumes. This is why they are mostly used as means of assessment for mid and higher-level jobs rather than entry or beginner-level jobs.
            </div>
            <h4 className={classess.heading_four}>
                Limitations
            </h4>
            <div className={classess.content_styling}>
                The test allows to map a person's education and knowledge level in accordance to the job they are applying for, but it does not allow for the measuring of other aspects required for employment such as learning, teamwork abilities, etc. Instead, the test focuses on theoretical knowledge and ignores all other aspects an employer might want to see in an employee.
            </div>
            <h3 className={classess.heading_three}>
                2. Personality Tests
            </h3>
            <center>
                <picture>
                    <source srcSet={personality_test_webp} type="imgae/webp" />
                    <source srcSet={personality_test} type="image/png" />
                    <img srcSet={personality_test_webp} alt="Sub" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
                Personality tests are rapidly gaining popularity due to their adaptable nature of testing. In complete contrast with aptitude tests, personality tests don't have right or wrong answers and neither do they focus on a particular topic or field. Instead, through their objective nature, they are used to predict the particular behavioural tendencies required for the position in a pool of candidates.
            </div>
            <div className={classess.content_styling}>
                Personality tests are often based on the ''Big Five (also known as the five-factor model') or the personality traits developed by Carl Jung. Meaning, if based on the first model, they test for the five major personality models, agreeableness, Conscientiousness, Extroversion, Openness, and Stress Management, per what the job requires. The second model, much like the first, aims to single out candidates with the required 'traits' or type of personality, such as introvert or extrovert, thinker or feeler, Type A or Type B. However, since personality tests are meant to be objective, they are usually a combination of these two most popular forms rather than a strictly copied version of either.
            </div>
            <h4 className={classess.heading_four}>
                Limitations
            </h4>
            <div className={classess.content_styling}>
                Personality tests can be useful in figuring out whether a person is a good fit, attribute, and character-wise. However, with testing something as abstract as personality, the aspect of invasiveness might also come in. Some candidates might find such questions too intrusive or personal, resulting in them closing off and reducing the effectiveness of the test. Moreover, with objective tests, many people might be focused on presenting answers that make them likeable rather than the truth, which could compromise the test and result in a bad hire.
            </div>
            <h3 className={classess.heading_three}>
                3. Emotional Intelligence Tests
</h3>
            <center>
                <picture>
                    <source srcSet={emotional_test_webp} type="imgae/webp" />
                    <source srcSet={emotional_test} type="image/png" />
                    <img srcSet={emotional_test_webp} alt="Sub" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
                Unlike cognitive or personality tests, emotional ability tests are relatively recent and have not managed to gain as much popularity as yet. However, despite its rather objective nature, much like cognitive tests, they are used to measure a potential candidate’s emotional response and development to deem if they are appropriate for the job at hand or not.
</div>
            <div className={classess.content_styling}>
                Emotional tests are meant to measure and gauge candidates' abilities of collaboration, motivation, teamwork, and personal relationships and are generally used to hire people for positions that require interpersonal connections and human interaction such as management and leadership roles.
</div>
            <h4 className={classess.heading_four}>
                Limitations
</h4>
            <div className={classess.content_styling}>
                With all objective tests, the question of dishonesty always exists. The candidate may be lying about their emotional responses to get employed and there would be no decisive way of obtaining honest and effective answers. Mental health experts may be brought in, however that would diminish the entire point of pre-employment assessment since it’s meant to reduce hiring costs.
</div>

            <h3 className={classess.heading_three}>
                4. Skills Test
            </h3>
            <center>
                <picture>
                    <source srcSet={skill_test} type="image/png" />
                    <source srcSet={skill_test_webp} type="imgae/webp" />
                    <img srcSet={skill_test} alt="Sub" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
                Skills test to measure candidates' level of acquired skills. They can be used to find broad skills such as technology and speaking proficiency or basic arithmetic skill etc. and because of that, skill tests are usually used to narrow down candidates for entry-level jobs.
            </div>
            <div className={classess.content_styling}>
                Skill tests are a good way of looking for a particular required talent or skillset, such as typing speed, knowledge regarding a particular field or software and because of this, they cannot be used to measure long-term job performances. They are typically designed to find someone with a particular, required skill set to meet a current and specific need and hence are not used to hire employees on a long-term employment basis.
            </div>
            <h4 className={classess.heading_four}>
                Limitations
            </h4>
            <div className={classess.content_styling}>
                With evaluating a certain skill set, time management is essential. In testing for skills such as writing or drawing, the candidate might need time to present their work and in other skills such as programming computers or working with technology or software, specialists will be needed to properly evaluate the skillset.
            </div>

            <h3 className={classess.heading_three}>
                5. Risk Test
</h3>
            <center>
                <picture>
                    <source srcSet={risk_test_webp} type="imgae/webp" />
                    <source srcSet={risk_test} type="image/png" />
                    <img srcSet={risk_test_webp} alt="Sub" loading="lazy" className={classess.main_image_styling} />
                </picture>
            </center>
            <div className={classess.content_styling}>
                Risk tests cover a rather extensive range of testing types to ensure that organizations and employers incur the smallest amount of risks or losses possible. Much like personality tests, risk assessments are used to look for counterproductive work behaviour (CWB), such as laziness, tardiness, dishonesty, etc, that helps narrow down and remove candidates not fit for the required position.
</div>
            <div className={classess.content_styling}>
                Risk tests are commonly used for positions such as retail, to avoid theft, manufacturing sets to avoid accidents through rule violation, and for caretaker, jobs to avoid any mishaps due to carelessness or bad behaviour.
</div>
            <h4 className={classess.heading_four}>
                Limitations
</h4>
            <div className={classess.content_styling}>
                In risk assessment tests, the results can often be paradoxical, leading to more problems than solutions. That is to say, when tested on integrity, there is no guarantee that the candidate would be telling the truth and if they did admit to something, they would not be hired.
</div>
            <h2 className={classess.heading_two}>
                How Widespread is Pre-Employment Testing?
</h2>
            <div className={classess.content_styling}>
                In recent years, especially after the internet's increased and very vital role in the hiring process, pre-employment testing is starting to gain more and more popularity. This is mainly due to the large pool of candidates that apply for every job opening. The excessiveness of job applications is akin to spamming, where people tend to drop resumes and applications for positions they are aware they don’t meet the requirements of. Due to this, not only do hiring professionals and managers find it more and more difficult to sift through a large number of applications to find the right candidate for the job but also leads to a spike in rejection rates. Where, in many cases, applicants fit for the job also tend to get rejected due to the bulk of applications.
</div>
            <div className={classess.content_styling}>
                Due to the excessive applications, the popularity of pre-employment assessment has been increasing rapidly for the past 15 or so years. The AMA which is the American Management Association, which conducts surveys revolving around small to medium-sized American businesses reported that 70% of employers use some sort of skill-based testing while 46% use personality or psychological evaluations-based hiring methods.

</div>
            <h2 className={classess.heading_two}>
                What to Expect From Pre-Employment Testing
</h2>
            <div className={classess.content_styling}>
                Despite the rapid popularization of the pre-employment testing process, it is still important to take an evaluative approach towards the entire process. Pre-employment tests are based on data-driven metrics that are aimed towards improving the hiring process. They are meant to streamline candidates towards the right job opportunities and making the employing process more efficient and accurate. The entire system is designed to increase productivity by saving money and time on the hiring and training process.
</div>
            <div className={classess.content_styling}>
                However, even with a carefully designed and advanced method of hiring, discrepancies exist. One of them happens to be the factor of dishonesty that is present especially in the case of tests like personality tests, which may result in a bad hire. Moreover, despite the test showing positive results, some candidates may still prove to be bad employees. And while each test is usually designed for maximum accuracy, mistakes can still be made. This is why the pre-employment assessment should be one of the processes of hiring rather than a make-or-break decision-maker factor.
</div>
            <h2 className={classess.heading_two}>
                Are these Tests Legal and Ethical?
</h2>
            <div className={classess.content_styling}>
                The pre-employment tests are completely legal since they only act as an alternative means of hiring. However, if the tests are being used in a manner that perpetuates or encourages racism, sexism, or any sort of bias and uses that as means of discrimination in the hiring process, then it can be counted as illegal. Tests such as cognitive testing can be used to perpetuate bias based on skin colour and race, depending on the way they are structured and personality tests can be used to stereotype candidates. However, as long as these implications are explicitly avoided, the tests are fully legal.
</div>
            <div className={classess.content_styling}>
                Pre-employment tests are also fully ethical since they are merely deciding an individual's ability to perform in a certain job. However, the questions of ethics can be brought up in the case of personality and emotional testing if the candidate is not aware they are being tested or if they are asked questions that are too private or inappropriate to the work environment.
</div>

        </div>
        <Social url={`https://blogs.mycareerdreams.com${props.location.path}`}/>
        <Footer/>
    </React.Fragment>
}
export default PreEmploymentTest;