import React from 'react'
import PreEmploymentTest from '../components/recruitment_blogs/blogs/pre_employment_test/pre_employment_test'
import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
       <SEO title="5 Types of Pre Employment Tests in 2021"
        description="Types of Pre Employment testing: 1. Cognitive 2. Personality 3. Emotional Intelligence 4. Skills  5. Risk. What are the benefits and limitations of each type?"
        url="/pre-employment-test/"
        image="https://blogs.mycareerdreams.com/blogs/pre_employment.webp"
        type="article"
      />
    <PreEmploymentTest location={location}/>
  
  </React.Fragment>
  )
}
export default Page;